import * as S from "./foodBloodBox.styles";
import { ReactComponent as IconOptions } from "../../../styles/assets/svg/icon-diet-options.svg";
import { ReactComponent as IconDietMenu } from "../../../styles/assets/svg/icon-dietmenu-sm.svg";
import { ReactComponent as ImgDiabetes } from "../../../styles/assets/svg/img-diabetes.svg";

export const FoodBloodBox = () => {
  return (
    <S.FoodBloodBox>
      <ImgDiabetes />
      <S.TxtGuideBox>
        연속혈당 기반 맞춤형 당뇨병 예측 결과는 <span>등록하신 식단정보</span>에
        대한 영양소 분석 데이터와 <span>식전후 연속혈당 정보</span>를 기반으로
        제공됩니다.
      </S.TxtGuideBox>
      <S.RouteGuideBox>
        식단별 나의 연속혈당 정보는{" "}
        <span>
          <S.DietMenuSpan>
            <IconDietMenu />
            식단관리
          </S.DietMenuSpan>{" "}
          -{" "}
          <S.DietOptionSpan>
            <IconOptions height={12} />
            옵션
          </S.DietOptionSpan>{" "}
          - [혈당보기]
        </span>{" "}
        에서 확인하실 수 있습니다.
      </S.RouteGuideBox>
    </S.FoodBloodBox>
  );
};
