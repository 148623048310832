import styled from "styled-components";

export const PredictTemplate = styled.div`
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
`;

export const BtnSection = styled.section`
  position: relative;
  display: -webkit-box;
  display: flex;
  width: 100vw;
  height: 56px;
  padding: 0 16px;

  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  z-index: 10;
`;
