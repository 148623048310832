import styled from "styled-components";

export const HistoryListBox = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 10px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const HistoryTit = styled.div`
  display: -webkit-box;
  display: flex;
  font-size: 1.8rem;
  font-wight: 500;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const HistoryContent = styled.ul`
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 10px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
