import styled from "styled-components";

export const FoodBloodList = styled.div`
  overflow: auto;
  display: -webkit-box;
  display: flex;
  width: 100vw;
  padding: 20px;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 40px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;

export const NodataWrapper = styled.div`
  text-align: center;
  font-size: 1.4rem;
  padding: 15px 20px;
  color: ${(props) => props.theme.colors.headerTxt};
  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
`;
