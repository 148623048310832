import styled, { css } from "styled-components";
import { InfoItems } from "../../atoms/InfoItems/InfoItems.styles";

interface InfoListBoxProps {
  grid?: boolean;
}

export const InfoListBox = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 8px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const InfoHeader = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const InfoTit = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

export const InfoContent = styled.div`
  display: -webkit-box;
  display: flex;
  padding: 24px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.btn};
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 20px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const InfoItemBox = styled.div<InfoListBoxProps>`
  ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 0;
    `}
`;
