import { ChangeEvent, useRef, useState } from "react";
import { HemoTemplate } from "../../components/templates/HemoTemplate";
import { Button } from "@mui/material";
import axios from "axios";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translate(150%);
  }
`;

const SkeletonInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const SkeletonItem = styled.li`
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  white-space: pre-wrap;
`;
const SkeletonName = styled.p`
  width: 100%;
  height: 18px;
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: ${shimmer} 2s infinite linear;
  }
`;
const Skeleton = () => {
  return (
    <SkeletonItem>
      <SkeletonInfo>
        <SkeletonName />
        <SkeletonName />
        <SkeletonName />
        <SkeletonName />
      </SkeletonInfo>
    </SkeletonItem>
  );
};

export default function Food() {
  const [image, setImage] = useState<string | null>(null);
  const [file, setFile] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      setMessage(null);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = async () => {
    try {
      setLoading(true);
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const apiUrl = "https://food-ai.insystem.kr/resultPhoto";
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("API 응답:", response.data);
        setMessage(response.data.choices[0].message.content);
      } else {
        console.log("이미지를 먼저 업로드해주세요.");
      }
    } catch (error) {
      alert("요청량이 많습니다. 5분 후 다시 시도하세요.");
      console.error("이미지 업로드 오류:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <HemoTemplate>
      <div>
        {!loading && !image && (
          <Button
            style={{
              width: "100%",
              height: "70px",
              fontSize: "20px",
              background: "#4755EB",
            }}
            variant="contained"
            onClick={handleClick}
          >
            사진등록
          </Button>
        )}

        <input
          ref={fileInputRef}
          id="upload-button"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        {image && (
          <div>
            <img
              src={image}
              alt="업로드된 이미지"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
        {image && !loading && !message && (
          <Button
            style={{
              width: "100%",
              height: "70px",
              fontSize: "20px",
              background: "#4755EB",
            }}
            variant="contained"
            onClick={uploadImage}
          >
            영양분석
          </Button>
        )}
        {loading && <Skeleton />}
        {message && <SkeletonItem>{message}</SkeletonItem>}
      </div>
    </HemoTemplate>
  );
}
