import { createGlobalStyle } from "styled-components";

export const GlobalFonts = createGlobalStyle`

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/NotoSansKR-Thin.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Thin.woff) format('woff'), url(./assets/fonts/NotoSansKR-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/NotoSansKR-Light.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Light.woff) format('woff'), url(./assets/fonts/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NotoSansKR-Regular.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Regular.woff) format('woff'), url(./assets/fonts/NotoSansKR-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/NotoSansKR-Medium.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Medium.woff) format('woff'), url(./assets/fonts/NotoSansKR-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/NotoSansKR-Bold.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Bold.woff) format('woff'), url(./assets/fonts/NotoSansKR-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(./assets/fonts/NotoSansKR-Black.woff2) format('woff2'), url(./assets/fonts/NotoSansKR-Black.woff) format('woff'), url(./assets/fonts/NotoSansKR-Black.otf) format('opentype');
} 
@font-face {
  font-family: 'Roboto';
  font-style: normal;  
  font-weight: 100;
  src: url(./assets/fonts/Roboto-Thin.woff2) format('woff2'), url(./assets/fonts/Roboto-Thin.woff) format('woff'), url(./assets/fonts/Roboto-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Roboto';  
  font-style: normal;  
  font-weight: 300;
  src: url(./assets/fonts/Roboto-Light.woff2) format('woff2'), url(./assets/fonts/Roboto-Light.woff) format('woff'), url(./assets/fonts/Roboto-Light.otf) format('opentype');
}
@font-face {   
  font-family: 'Roboto';   
  font-style: normal;   
  font-weight: 400;
  src: url(./assets/fonts/Roboto-Regular.woff2) format('woff2'), url(./assets/fonts/Roboto-Regular.woff) format('woff'), url(./assets/fonts/Roboto-Regular.otf) format('opentype'); 
}
@font-face {   
  font-family: 'Roboto';   
  font-style: normal;   
  font-weight: 500;
  src: url(./assets/fonts/Roboto-Medium.woff2) format('woff2'), url(./assets/fonts/Roboto-Medium.woff) format('woff'), url(./assets/fonts/Roboto-Medium.otf) format('opentype'); 
}
@font-face {   
  font-family: 'Roboto';   
  font-style: normal;   
  font-weight: 700;
  src: url(./assets/fonts/Roboto-Bold.woff2) format('woff2'), url(./assets/fonts/Roboto-Bold.woff) format('woff'), url(./assets/fonts/Roboto-Bold.otf) format('opentype'); 
}
`;
