import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  gradient: {
    skeleton:
      "linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%)",
    gradientGauge:
      "linear-gradient(90deg, #54DC9D 0%, #FFCD2E 36.98%, #FF9726 63.02%, #EE2A59 100%)",
  },
  shadow: {
    primary: "0 2px 6px -3px rgba(0,0,0,0.1)",
    formTemplate: "0 -2px 10px -4px rgba(0,0,0,0.25)",
    navigation: "0 2px 10px 0 rgba(0,0,0,0.07)",
    btn: "0 2px 10px -5px rgba(0,0,0,0.15)",
  },
  colors: {
    // PALETTE
    primary: "#4755EB",
    primaryActive: "#2B39D0",
    secondary: "#4344E1",
    secondaryActive: "#2021B7",
    highlight: "#F6A200",
    highlightActive: "#F69100",
    cancel: "#A5A8BA",
    cancelActive: "#9295A8",
    dotted: "#DF6464",
    dottedActive: "#CD4545",
    terms: "#676B85",
    termsActive: "#4B5070",
    disabled: "#EAECF3",
    white: "#ffffff",
    whiteActive: "#F6F7FA",
    black: "#000000",
    green: "#34CD86",
    orange: "#f59057",
    light: "#BFC1F033 20%",

    // TEXT
    titleTxt: "#2E397C",
    darkTxt: "#2B2F45",
    headerTxt: "#676B85",
    iconNavTxt: "#484848",
    iconNavActiveTxt: "#2E397C",
    tabTxt: "#A5A8BA",
    listTitTxt: "#2B2F45",
    unitTxt: "#797979",
    expTxt: "#565656",
    noitemTxt: "#535353",
    dottedTxt: "#DF6464",
    avatarTxt: "#020F1A",

    // BORDER
    linkBtnBorder: "#F2F3FC",
    divideBorder: "#EDEEF3",
    itemExpborder: "#F2F3FC",

    // BACKGROUND
    mainBg: "#F9F9F9",
    listCheckedBg: "#F2F3FC",
    shadeBg: "#F3F3F3",
    handleBg: "#EEEEEE",
    skeleton: "#e5e5e5",
    avatarBox: "#323A4C",
    goodBg: "#54DC9D",
    fineBg: "#FFCD2E",
    warnBg: "#FF9726",
    dangerBg: "#EE2A59",

    // LAYOUT

    // INPUT
    labelTxt: "#797979",
    inputBorder: "#DFDFDF",
    inputBg: "#F9F9F9",
    inputDisabledBg: "#DFDFDF",
  },
};
