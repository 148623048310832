import { HemoTemplate } from "../../components/templates/HemoTemplate";
import { InfoList } from "../../components/organisms/InfoList";
import { HistoryList } from "../../components/organisms/HistoryList";
import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../../lib/api";
import { useEffect, useState } from "react";
import { bool, boolean } from "yup";
import { Modal } from "../../components/molecules/Modal";
import { Button, ButtonGroup } from "../../components/atoms/Button";
import { showSnackBar } from "../../lib/flutterFuntions";

export interface healthData {
  HbA1c: number;
  WAIST: number;
  height: number;
  id: number;
  name: string;
  userId: string;
  weight: number;
  age: number;
  sex: string;
  measure_date: string;
}
export interface editHealthProps {
  HbA1c: number;
  WAIST: number;
  height: number;
  weight: number;
  measure_date: string;
  id?: number;
  userId?: string;
  reg_time?: string;
}
export default function HemoInfo() {
  // 모달 오픈 상태 state
  const [modalPop, setModalPop] = useState(false);
  // 편집 상태확인 state
  const [edit, setEdit] = useState(false);
  // 건강정보 수정 상태 state
  const [editHealthInfo, setEditHealthInfo] = useState<editHealthProps>({
    height: 0,
    weight: 0,
    WAIST: 0,
    HbA1c: 0,
    measure_date: "",
  });
  // 히스토리 현재 선택 item id state
  const [selectedItem, setSelectedItem] = useState(0);

  const handleSelected = (checkup: any) => {
    setSelectedItem(checkup);
  };

  /** 건강정보  */
  const { data: healthData, refetch } = useQuery(["user_profile"], () =>
    request<healthData>({
      method: "GET",
      url: `user/profile`,
    }).then((res) => {
      setEditHealthInfo({
        height: res.height,
        weight: res.weight,
        WAIST: res.WAIST,
        HbA1c: res.HbA1c,
        measure_date: res.measure_date,
      });
      return res;
    })
  );

  /** 나의 건강 정보 update trigger 함수 */
  const { mutateAsync: updateHealthData } = useMutation(
    () =>
      request<editHealthProps>({
        method: "POST",
        url: `health/edit-info`,
        data: editHealthInfo,
      }),
    {
      onSuccess: () => {
        refetch();
        historyRefetch();
      },
    }
  );

  /** 건강정보 히스토리 data */
  const { data: healthHistory, refetch: historyRefetch } = useQuery(
    ["health_history"],
    () =>
      request<editHealthProps[] | any>({
        method: "GET",
        url: `health/history`,
      }).then((res) => {
        return res;
      })
  );

  /** 건강정보 히스토리 선택삭제 로직 */
  const { mutateAsync: deleteHealthInfo } = useMutation(
    () =>
      request<editHealthProps>({
        method: "DELETE",
        url: `health/history/${selectedItem}`,
      }),
    {
      onSuccess: () => {
        setModalPop(false);
        historyRefetch();
      },
    }
  );

  // 편집모드 트리거
  const onEditHandler = () => {
    setEdit((prev) => !prev);
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultDateValue = new Date(date).toISOString().split("T")[0];

    // 편집버튼 클릭 시 setData 오늘날짜로 초기화
    setEditHealthInfo({
      ...editHealthInfo,
      measure_date: defaultDateValue,
    });
  };

  // 편집 종류 후 저장
  const onSaveEdit = () => {
    updateHealthData();
    setEdit((prev) => !prev);
    showSnackBar("나의 건강정보 저장 완료.");
  };

  //히스토리 선택 삭제 버튼 클릭 시 모달 pop 트리거
  const handleModal = () => {
    setModalPop(!modalPop);
  };

  return (
    <HemoTemplate>
      <>
        <InfoList
          healthData={healthData}
          onEditHandler={onEditHandler}
          onSaveEdit={onSaveEdit}
          edit={edit}
          editHealthInfo={editHealthInfo}
          setEditHealthInfo={setEditHealthInfo}
        />
        <HistoryList
          healthHistory={healthHistory}
          onDeleteHistory={handleModal}
          handleSelected={handleSelected}
          selectedItem={selectedItem}
        />
        {modalPop && (
          <Modal title={"경고"} onTopClose={handleModal}>
            <>
              <div>삭제하시겠습니까?</div>
              <ButtonGroup marginTop={30}>
                <>
                  <Button
                    type="button"
                    label="취소"
                    size="lg"
                    color="cancel"
                    onClick={handleModal}
                  />
                  <Button
                    type="button"
                    label="삭제"
                    size="lg"
                    color="primary"
                    onClick={deleteHealthInfo}
                  />
                </>
              </ButtonGroup>
            </>
          </Modal>
        )}
      </>
    </HemoTemplate>
  );
}
