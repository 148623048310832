export function showSnackBar(text: string = "") {
  const arg = [text];

  window.flutter_inappwebview?.callHandler("showSnackBar", arg);
}
export function navigatorPushScreen(
  url: string,
  title: string = "",
  type: string = "normal",
  callback?: Function
) {
  const arg = [url, type, title];

  window.flutter_inappwebview
    ?.callHandler("navigatorPushScreen", arg)
    .then(function (result: any) {
      if (callback) {
        callback(result);
      }
    });
}
export function navigatorPushResultScreen(
  url: string,
  title: string = "",
  type: string = "normal",
  callback?: Function
) {
  const arg = [url, type, title];

  window.flutter_inappwebview
    ?.callHandler("navigatorPushResultScreen", arg)
    .then(function (result: any) {
      if (callback) {
        callback(result);
      }
    });
}

// 나만의 걷기지점 리스트에서 바로 미션 시작하기 위한 라우터
export function navigatorPushStartMissonScreen(data: {}, callback?: Function) {
  const arg = [data];

  window.flutter_inappwebview
    ?.callHandler("navigatorPushStartMissonScreen", arg)
    .then(function (result: any) {
      if (callback) {
        callback(result);
      }
    });
}

export function navigatorRoutePushScreen(
  url: string,
  data?: any,
  callback?: Function
) {
  const arg = [url, String(data)];
  window.flutter_inappwebview
    ?.callHandler("navigatorRoutePushScreen", arg)
    .then(function (result: any) {
      if (callback) {
        callback(result);
      }
    });
}

export function getStep() {
  return window.flutter_inappwebview?.callHandler("getStep");
}

export function resetStep() {
  return window.flutter_inappwebview?.callHandler("resetStep");
}

export function navigatorPopScreen(args?: any) {
  window.flutter_inappwebview?.callHandler("navigatorPopScreen", [args]);
}

export function navigatorPushEditHealth() {
  window.flutter_inappwebview
    ?.callHandler("navigatorPushEditHealth")
    .then(function (result: any) {});
}

export function writeAccessToken(accessToken: string) {
  window.flutter_inappwebview?.callHandler("writeAccessToken", [accessToken]);
}

export async function readAccessToken() {
  return await window.flutter_inappwebview?.callHandler("readAccessToken");
}

export async function readRefreshToken() {
  return await window.flutter_inappwebview?.callHandler("readRefreshToken");
}
export function login(accessToken: string, refreshToken: string) {
  window.flutter_inappwebview
    ?.callHandler("login", [accessToken, refreshToken])
    .then(function (result: any) {});
}

export function logout() {
  window.flutter_inappwebview?.callHandler("logout");
}

export function customScript(callback: Function) {
  window.flutter_inappwebview?.callHandler("customScript").then(callback);
}

export async function getCoords() {
  return await window.flutter_inappwebview?.callHandler("getCoords");
}

export async function getHeading() {
  return await window.flutter_inappwebview?.callHandler("getHeading");
}

export function addWalkLocation(callback: Function) {
  window.flutter_inappwebview?.callHandler("addWalkLocation").then(callback);
}

export async function deleteMapPoint() {
  return await window.flutter_inappwebview?.callHandler("deleteMapPoint");
}
