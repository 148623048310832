import { FoodBloodList } from "../../../components/organisms/FoodBloodList";
import { LoadingSection } from "../../../components/organisms/LoadingSection";
import { PredictTemplate } from "../../../components/templates/PredictTemplate";

export default function FoodBlood() {
  return (
    <PredictTemplate>
      <>
        {/* {isLoading && <LoadingSection />} */}
        <FoodBloodList />
      </>
    </PredictTemplate>
  );
}
