import { ButtonHTMLAttributes } from "react";
import * as S from "./button.styles";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  color?: string;
  size?: string;
  width?: string;
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  padding?: number;
  icon?: any;
  edit?: boolean;
  form?: any;
  label?: string;
  array?: string;
  disable?: boolean;
  onClick?: any;
  disabled?: boolean;
  children?: React.ReactElement;
  direction?: string;
}

export const ButtonGroup = ({
  array,
  marginBottom,
  marginTop,
  children,
  width,
  direction,
}: ButtonProps) => {
  return (
    <S.ButtonGroupBox
      direction={direction}
      array={array}
      marginBottom={marginBottom}
      marginTop={marginTop}
      width={width}
    >
      {children}
    </S.ButtonGroupBox>
  );
};

export const Button = ({
  type,
  color,
  size,
  width,
  height,
  icon,
  edit,
  form,
  label,
  onClick,
  disabled,
  children,
  padding,
  ...rest
}: ButtonProps) => {
  return (
    <S.ButtonBox
      type={type}
      color={color}
      size={size}
      width={width}
      height={height}
      icon={icon}
      edit={edit}
      form={form}
      onClick={onClick}
      disabled={disabled}
      padding={padding}
      label={label}
      {...rest}
    >
      <>
        {children}
        {label}
      </>
    </S.ButtonBox>
  );
};
