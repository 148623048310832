import styled from "styled-components";

export const FoodBloodBox = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 25px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  & > svg {
    margin-bottom: 5px;
  }
`;

export const TxtGuideBox = styled.div`
  width: 100%;
  padding: 0 10px;
  font-size: 1.6rem;
  line-height: 2.2rem;

  span {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }
`;

export const RouteGuideBox = styled.div`
  width: 100%;
  padding: 15px;
  color: ${(props) => props.theme.colors.headerTxt};
  font-size: 1.4rem;
  line-height: 2rem;
  background: ${(props) => props.theme.colors.whiteActive};
  border-radius: 5px;

  & > span {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }
`;

export const DietMenuSpan = styled.span`
  svg {
    margin: 0 4px;
  }
`;

export const DietOptionSpan = styled.span`
  svg {
    margin: 0px 5px;
  }
`;
