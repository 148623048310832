import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./styles/global-style";
import { GlobalFonts } from "./styles/assets/fonts/fonts";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a4a16188e6d6f3614b37ef940ae2316d@o4506138103709696.ingest.sentry.io/4506138108231680",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
root.render(
  <>
    <GlobalStyle />
    <GlobalFonts />
    <App />
  </>
);

reportWebVitals();
