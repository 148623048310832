import { useEffect, useState } from "react";
import {
  navigatorPushResultScreen,
  readAccessToken,
} from "../../../lib/flutterFuntions";
import { Button, ButtonGroup } from "../../atoms/Button";
import { FoodBloodBox } from "../../molecules/FoodBloodBox";
import { InfoListBox } from "../../molecules/InfoListBox";
import * as S from "./foodBloodList.styles";

export const FoodBloodList = () => {
  const [authToken, setAuthToken] = useState("");
  useEffect(() => {
    async function getAuthToken() {
      const accessToken = await readAccessToken();
      setAuthToken(accessToken);
    }
    getAuthToken();
  }, []);

  return (
    <>
      <S.FoodBloodList>
        <InfoListBox>
          <FoodBloodBox />
        </InfoListBox>

        {/* 결과보기 버튼 */}
        <ButtonGroup marginTop={10}>
          <Button
            type="button"
            label="결과보기"
            size="lg"
            color="primary"
            onClick={() =>
              navigatorPushResultScreen(
                `/cgm/${authToken}`,
                "연속혈당 분석결과"
              )
            }
          />
        </ButtonGroup>
      </S.FoodBloodList>
    </>
  );
};
