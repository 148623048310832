import styled, { css } from "styled-components";
import { ButtonProps } from ".";

export const ButtonGroupBox = styled.div<ButtonProps>`
  grid-area: BT;
  display: -webkit-box;
  display: flex;
  width: ${(props) => (props.width ? props.width + "%" : "100%")};
  -webkit-box-pack: ${(props) => props.array};
  justify-content: flex-${(props) => props.array};
  gap: 5px;

  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
    
    ${(props) =>
    props.direction === "vertical" &&
    css`
      -webkit-box-orient: vertical;
      flex-direction: column;
    `}

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const ButtonBox = styled.button<ButtonProps>`
  display: -webkit-box;
  display: flex;
  padding: ${(props) => (props.padding ? props.padding + "px" : 0)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop + "px" : 0)};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : 0};
  color: ${(props) =>
    props.color === "white"
      ? props.theme.colors.secondary
      : props.color
      ? props.theme.colors.white
      : props.theme.colors.secondary};
  background: ${(props) =>
    props.color === "white"
      ? props.theme.colors.white
      : props.color === "transparent"
      ? "none"
      : props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.primary};
  border: ${(props) =>
    props.color === "white"
      ? "1px solid " + props.theme.colors.secondary
      : "1px solid " + props.color
      ? "none"
      : "1px solid " + props.theme.colors.secondary};
  border-radius: 5px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 4px;

  &:disabled,
  &[disabled] {
    background: ${(props) => props.theme.colors.disabled};

    &:active {
      background: ${(props) => props.theme.colors.disabled};
      font-weight: inherit;
    }
  }

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  // 버튼 클릭시 효과
  &:active {
    font-weight: 600;
    background: ${(props) =>
      props.color === "transparent"
        ? props.theme.colors.whiteActive
        : props.color
        ? props.theme.colors[props.color + "Active"]
        : props.theme.colors.primaryActive};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
  }

  &:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
  }

  // 너비 props지정
  ${(props) =>
    props.width &&
    css`
      width: ${props.width} !important;
    `}

  // 높이 props지정
  ${(props) =>
    props.height &&
    css`
      height: ${props.height + "px"} !important;
      font-size: ${props.height >= 40 ? "1.6rem" : "1.4rem"} !important;
    `}

    
    // 간편인증 버튼 스타일 지정
    ${(props) =>
    props.color === "link" &&
    css`
      padding-left: 16px;
      padding-right: 0;
      color: ${(props) => props.theme.colors.darkTxt};
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.linkBtnBorder};
      box-shadow: ${(props) => props.theme.shadow.btn};

      &:active {
        background: ${(props) => props.theme.colors.whiteActive};
      }
    `}

    // 약관 더보기 버튼 스타일 지정
    ${(props) =>
    props.color === "syncTerm" &&
    css`
      color: ${(props) => props.theme.colors.terms};
      border: 1px solid ${(props) => props.theme.colors.terms};
      background: ${(props) => props.theme.colors.white};
    `}

    // 걷기미션 바로가기 버튼 스타일 지정
    ${(props) =>
    props.color === "mission" &&
    css`
      padding: 0 10px 0 20px;
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.primary};
      border-radius: 10px 10px 0 0 !important;
      box-shadow: ${(props) => props.theme.shadow.btn};
    `}

    //사이드메뉴 버튼 스타일 지정
    ${(props) =>
    props.color === "sidemenu" &&
    css`
      width: 100%;
      height: 60px;
      padding: 0 26px;
      color: ${(props) => props.theme.colors.headerTxt};
      background: none;

      &:active {
        color: ${(props) => props.theme.colors.primary};
        background: ${(props) => props.theme.colors.whiteActive};

        svg {
          path {
            fill: ${(props) => props.theme.colors.primary};
          }
        }
      }

      .btn-content {
        display: -webkit-box;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;

        span {
          display: -webkit-box;
          display: flex;
          letter-spacing: -0.05rem;
          -webkit-box-align: center;
          align-items: center;
          gap: 15px;

          //IOS, safari 14 이하 버전 gap 적용
          @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & > *:not(:last-child) {
              margin-right: 15px;
            }
          }
        }
      }
    `}

  // 아이콘 버튼 스타일 지정
  ${(props) =>
    props.icon === true &&
    css`
      display: -webkit-box;
      display: flex;
      padding: 0;
      background: none;
      border: none;
      color: ${(props) => props.theme.colors.iconNavTxt};
      font-size: 1rem;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      flex-direction: column;
      gap: 6px;

      //IOS, safari 14 이하 버전 gap 적용
      @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        & > *:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    `}

  ${(props) =>
    props.icon === true &&
    !props.form &&
    css`
      &:active {
        color: ${(props) => props.theme.colors.iconNavActiveTxt};
        background: ${(props) => props.theme.colors.whiteActive};

        svg {
          path {
            fill: ${(props) => props.theme.colors.iconNavActiveTxt};
          }
        }
      }
    `}

    ${(props) =>
    props.icon === true &&
    props.form &&
    css`
      &:active {
        background: rgba(0, 0, 0, 0.2);
      }
    `}


  // xsm 사이즈(높이 30px, 폰트사이즈 14px)
  ${(props) =>
    props.size === "xsm" &&
    css`
      height: 30px;
      padding: 0 10px;
      color: ${props.color === "transparent"
        ? props.theme.colors.darkTxt
        : props.color === "white"
        ? props.theme.colors.secondary
        : props.theme.colors.white};
      font-size: 1.4rem;
    `}

  // lg 사이즈(높이 48px, 폰트사이즈 18px)
  ${(props) =>
    props.size === "lg" &&
    css`
      width: 100%;
      height: 48px;
      font-size: 1.8rem;
      font-weight: 500;
    `}
    
  ${(props) =>
    props.size === "lg" &&
    props.disabled &&
    css`
      width: 100%;
      height: 48px;
      font-size: 1.8rem;
      font-weight: 500;
      background: ${(props) => props.theme.colors.disabled};
    `}

     

  ${(props) =>
    props.disabled === true &&
    css`
      background-color: ${(props) => props.theme.shadow.btn} !important;

      &:active {
        background-color: ${(props) => props.theme.shadow.btn} !important;
      }
    `}


    ${(props) =>
    props.edit === true &&
    css`
      background: none;
      font-size: 1.3rem;

      border: ${props.label
        ? "1px solid " + props.theme.colors.primary
        : "none"};
      gap: 0px;

      span {
        padding-right: 6px;
      }

      &:active {
        background: ${(props) => props.theme.colors.whiteActive};
      }

      &:nth-child(2) {
        padding-left: 1px;
        justify-content: flex-start;
      }
    `}
`;
