import React from "react";
import * as S from "./InfoItems.styles";

interface InfoItemsProps {
  title: string;
  value?: number | string;
  input?: React.ReactElement;
  unit?: string;
  kor?: boolean;
  rows?: number;
}

export const InfoItems = ({
  title,
  value,
  input,
  unit,
  kor,
  rows,
}: InfoItemsProps) => {
  return (
    <S.InfoItems rows={rows}>
      <S.InfoItemTit>{title}</S.InfoItemTit>

      <S.InfoItemContent kor={kor}>
        {value && value}
        {input && input}
        {unit && <S.InfoItemUnit>{unit}</S.InfoItemUnit>}
      </S.InfoItemContent>
    </S.InfoItems>
  );
};
