import { Suspense, lazy } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import HemoInfo from "./pages/Home/HemoInfo";
import FoodBlood from "./pages/Prediction/FoodBlood";
import { LoginLoading } from "./components/atoms/LoginLoading";
import { DataLoading } from "./components/organisms/DataLoading";
import { LoadingSection } from "./components/organisms/LoadingSection";
import Food from "./pages/Food/Food";

function App() {
  const Home = lazy(() => import("./pages/Home/Home"));
  const Login = lazy(() => import("./pages/Auth/Login"));
  const Join = lazy(() => import("./pages/Auth/Join/Join"));
  const MyInfo = lazy(() => import("./pages/MyInfo/MyInfo"));
  const ClinicalInfo = lazy(() => import("./pages/Prediction/ClinicalInfo"));
  const DietPattern = lazy(() => import("./pages/Prediction/DietPattern"));
  const BloodGlucose = lazy(() => import("./pages/Prediction/BloodGlucose"));
  const EditHealthInfo = lazy(
    () => import("./pages/MyInfo/Edit/EditHealthInfo")
  );
  const SyncHistory = lazy(() => import("./pages/SyncMyInfo/SyncHistory"));
  const SyncInfoMethod = lazy(
    () => import("./pages/SyncMyInfo/SyncInfo/SyncInfoMethod")
  );
  const SyncInfoAuth = lazy(
    () => import("./pages/SyncMyInfo/SyncInfo/SyncInfoAuth")
  );
  const SyncInfoDone = lazy(
    () => import("./pages/SyncMyInfo/SyncInfo/SyncInfoDone")
  );
  const Walk = lazy(() => import("./pages/Walk/Walk"));
  const WalkPoint = lazy(() => import("./pages/Walk/WalkPoint"));
  const WalkMissionMap = lazy(
    () => import("./pages/Walk/WalkMission/MissionMap")
  );
  const WalkMissionStart = lazy(
    () => import("./pages/Walk/WalkMission/MissionStart")
  );
  const WalkMissionProgress = lazy(
    () => import("./pages/Walk/WalkMission/MissionProgress")
  );
  const WalkMissionDone = lazy(
    () => import("./pages/Walk/WalkMission/MissionDone")
  );
  const WalkCustomList = lazy(
    () => import("./pages/Walk/WalkCustom/CustomList")
  );
  const WalkCustomReg = lazy(() => import("./pages/Walk/WalkCustom/CustomReg"));
  const Diet = lazy(() => import("./pages/Diet/Diet"));
  const SideMenu = lazy(() => import("./pages/SideMenu"));
  const Notice = lazy(() => import("./pages/Board/Notice"));
  const NoticeDetail = lazy(() => import("./pages/Board/[id]"));
  const Refresh30M = lazy(() => import("./pages/Refresh"));
  const MyAvatar = lazy(() => import("./pages/MyAvatar"));

  const PointManage = lazy(
    () => import("./pages/Auth/PointManage/PointManage")
  );
  const Ranking = lazy(() => import("./pages/Walk/WalkRanking/ranking"));

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Suspense>
            <BrowserRouter>
              {/* <Refresh30M /> */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<div></div>}>
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Suspense fallback={<LoginLoading />}>
                      <Login />
                    </Suspense>
                  }
                />
                <Route path="/join/info-health" element={<Join />} />
                <Route path="/myinfo" element={<MyInfo />} />

                {/* 맞춤 당뇨병 예측 */}
                {/* 임상정보 */}
                <Route path="/clinicalInfo" element={<ClinicalInfo />} />
                {/* 식이패턴 */}
                <Route path="/dietPattern" element={<DietPattern />} />
                {/* 혈당모델링 */}
                <Route path="/bloodGlucose" element={<BloodGlucose />} />
                {/* 연속혈당 */}
                <Route path="/continuousBlood" element={<FoodBlood />} />

                <Route
                  path="/myinfo/edit/health"
                  element={<EditHealthInfo />}
                />

                <Route path="/sync/history" element={<SyncHistory />} />
                <Route path="/sync/info/method" element={<SyncInfoMethod />} />
                <Route path="/sync/info/auth" element={<SyncInfoAuth />} />
                <Route path="/sync/info/done" element={<SyncInfoDone />} />
                <Route path="/walk" element={<Walk />} />
                <Route path="/walk/point" element={<WalkPoint />} />
                <Route path="/walk/mission/map" element={<WalkMissionMap />} />
                <Route
                  path="/walk/mission/start"
                  element={<WalkMissionStart />}
                />
                <Route
                  path="/walk/mission/progress"
                  element={<WalkMissionProgress />}
                />
                <Route
                  path="/walk/mission/done"
                  element={<WalkMissionDone />}
                />
                {/* 나만의 걷기지점 리스트 페이지  */}
                <Route path="/walk/custom/list" element={<WalkCustomList />} />
                <Route
                  path="/walk/custom/register"
                  element={<WalkCustomReg />}
                />
                <Route path="/diet" element={<Diet />} />
                <Route path="/sidemenu" element={<SideMenu />} />
                <Route path="/hemoglo" element={<HemoInfo />} />
                <Route path="/notice" element={<Notice />} />
                <Route path="/notice/:id" element={<NoticeDetail />} />
                <Route path="/myavatar" element={<MyAvatar />} />
                <Route path="/food" element={<Food />} />

                <Route path="/PointManage" element={<PointManage />} />

                <Route
                  path="/walk/ranking/:category"
                  element={
                    <Suspense fallback={<LoadingSection />}>
                      <Ranking />
                    </Suspense>
                  }
                />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry 3
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

export default App;
