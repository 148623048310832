import { useLocation } from "react-router";
import { Button } from "../../atoms/Button";
import { navigatorPushScreen } from "../../../lib/flutterFuntions";
import * as S from "./predictTemplate.styles";

interface predictTemplate {
  children?: React.ReactElement;
  refetch?: any;
}

export const PredictTemplate = ({ children, refetch }: predictTemplate) => {
  const location = useLocation();

  return (
    <S.PredictTemplate>
      {location.pathname === `/clinicalInfo` && (
        <S.BtnSection>
          <Button
            type="button"
            label="정보수정"
            color="white"
            width="78px"
            height={36}
            onClick={() =>
              navigatorPushScreen(
                `/myinfo/edit/health`,
                "나의 임상정보 입력",
                "blue",
                () => {
                  setTimeout(() => {
                    refetch();
                  }, 500);
                }
              )
            }
          />
        </S.BtnSection>
      )}
      {children}
    </S.PredictTemplate>
  );
};
