import styled, { css } from "styled-components";
import { ModalStyleProps } from ".";
import { ButtonBox } from "../../atoms/Button/button.styles";

export const ModalBG = styled.div`
  position: fixed;
  display: -webkit-box;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.colors.black}b2;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalBox = styled.div<ModalStyleProps>`
  display: -webkit-box;
  display: flex;
  width: calc(100vw - 40px);
  max-height: 90vh;
  padding: ${(props) => (props.padding ? props.padding + "px" : "30px")};
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.primary};
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 20px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ${(props) =>
    props.size === "md" &&
    css`
      width: 300px;
    `}
`;

export const ModalHeader = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  font-size: 2rem;
  font-weight: 500;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const ModalBody = styled.div`
  overflow: auto;
  display: -webkit-box;
  display: flex;
  padding: 5px;
  color: ${(props) => props.theme.colors.expTxt};
  font-size: 1.4rem;
  line-height: 1.8rem;
  -webkit-box-orient: vertical;
  flex-direction: column;

  ${ButtonBox} {
    .btn-content {
      display: -webkit-box;
      display: flex;
      width: 100%;
      padding: 0 0 0 14px;
      font-size: 1.6rem;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      justify-content: space-between;

      &.load-info {
        svg {
          path {
            fill: ${(props) => props.theme.colors.primary};
          }
        }
      }

      &.sync-info {
        svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }
    }
  }
`;

export const ModalFooter = styled.div``;
