import normalize from "styled-normalize";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
${normalize}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  letter-spacing: -0.05rem;
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

* {
  box-size: border-box;
}

::-webkit-scrollbar {
    display: none;
    width: 0;
}

ul, li {
padding: 0;
margin: 0;
list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

.light {
  font-weight: 300
}

.normal {
  font-weight: 400
}

.bold {
  font-weight: 700
}

.bolder {
  font-weight: 800
}

.err-message {
  color: red;
  font-size: 1.1rem;
}
.message {
  font-size: 1.1rem;
}

// 버튼 로딩 
.material-loader {
  fill: none;
  stroke-width: 6;
  -webkit-animation: rotate 1000ms linear infinite;
  animation: rotate 1000ms linear infinite;
}

.material-loader__circle {
  stroke: #fff;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1500ms ease-in-out infinite;
  animation: dash 1500ms ease-in-out infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.25);
    transform: rotate(0deg) scale(0.25);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.25);
    transform: rotate(360deg) scale(0.25);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.4);
    transform: rotate(0deg) scale(0.4);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.4);
    transform: rotate(360deg) scale(0.4);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

`;
