import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  logout,
  readAccessToken,
  readRefreshToken,
  writeAccessToken,
} from "./flutterFuntions";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = await readAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = await readRefreshToken();
      if (!refreshToken) {
        return logout();
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/user/refresh-token`,
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );
        const accessToken = response?.data?.accessToken;
        writeAccessToken(accessToken);
        return api(originalRequest);
      } catch (e) {
        return logout();
      }
    }

    return Promise.reject(error);
  }
);

const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const { data } = await api.request<T>({ ...config });
    return data;
  } catch (error) {
    const { response }: any = error as unknown as AxiosError;
    if (response) {
      throw response.data;
    }

    throw error;
  }
};

const setToken = (token: string) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export { request, setToken };
