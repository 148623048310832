import * as S from "./InfoListBox.styles";

export const InfoListBox = ({ header, title, grid, children }: any) => {
  return (
    <S.InfoListBox>
      {title && <S.InfoTit>{title}</S.InfoTit>}
      <S.InfoContent>
        {header && <S.InfoHeader>{header}</S.InfoHeader>}
        <S.InfoItemBox grid={grid}>{children}</S.InfoItemBox>
      </S.InfoContent>
    </S.InfoListBox>
  );
};
