import styled, { css } from "styled-components";
import IconChecked from "../../../styles/assets/img/icon-checked.png";

export const HistoryItems = styled.li<{ active?: boolean }>`
  display: grid;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.btn};
  grid-template-columns: 24px 1fr;
  grid-template-rows: 1fr 16px;
  gap: 20px;

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.listCheckedBg};

      div,
      span {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    `}
`;

export const ItemRadioBox = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  grid-row: 1/3;

  input[type="radio"] {
    display: none;
  }

  label {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.inputBorder};
  }

  input[type="radio"]:checked + label {
    border: none;

    &::before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      left: 0;
      top: 0;
      background: url(${IconChecked});
      background-size: contain;
    }
  }
`;

export const ItemContent = styled.div`
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 25px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-right: 25px;
      margin-bottom: 10px;
    }
  }
`;

export const ItemDateBox = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.darkTxt};
  font-size: 1.4rem;
  -webkit-box: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
`;

export const ItemBox = styled.div`
  display: -webkit-box;
  display: flex;
  color: ${(props) => props.theme.colors.darkTxt};
  font-size: 1.4rem;
  font-weight: 500;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const ItemTit = styled.span`
  color: ${(props) => props.theme.colors.labelTxt};
  font-size: 1.2rem;
  font-weight: 400;
`;
