import * as S from "./loginLoading.styles";

export const LoginLoading = () => {
  return (
    <S.LoginLoading>
      <S.LoadingCircleBox>
        <svg
          className="pl"
          viewBox="0 0 160 160"
          width="80px"
          height="80px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stop-color="#000" />
              <stop offset="100%" stop-color="#fff" />
            </linearGradient>
            <mask id="mask1">
              <rect x="0" y="0" width="160" height="160" fill="url(#grad)" />
            </mask>
            <mask id="mask2">
              <rect x="28" y="28" width="104" height="104" fill="url(#grad)" />
            </mask>
          </defs>
          <g>
            <g className="pl__ring-rotate">
              <circle
                className="pl__ring-stroke"
                cx="80"
                cy="80"
                r="72"
                fill="none"
                stroke="hsl(223,90%,55%)"
                stroke-width="16"
                stroke-dasharray="452.39 452.39"
                stroke-dashoffset="452"
                stroke-linecap="round"
                transform="rotate(-45,80,80)"
              />
            </g>
          </g>
          <g mask="url(#mask1)">
            <g className="pl__ring-rotate">
              <circle
                className="pl__ring-stroke"
                cx="80"
                cy="80"
                r="72"
                fill="none"
                stroke="hsl(193,90%,55%)"
                stroke-width="16"
                stroke-dasharray="452.39 452.39"
                stroke-dashoffset="452"
                stroke-linecap="round"
                transform="rotate(-45,80,80)"
              />
            </g>
          </g>
        </svg>
      </S.LoadingCircleBox>
    </S.LoginLoading>
  );
};
