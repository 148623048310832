import { editHealthProps } from "../../../pages/Home/HemoInfo";
import * as S from "./historyItems.styles";

interface HistoryItemsProps {
  id: number;
  checkup: editHealthProps;
  selectedItem: number;
  onSelected: (checkup: number) => void;
}

export const HistoryItems = ({
  id,
  checkup,
  selectedItem,
  onSelected,
}: HistoryItemsProps) => {
  const pointDate =
    checkup &&
    checkup?.measure_date?.substring(0, checkup?.measure_date?.indexOf("T", 0));

  return (
    <S.HistoryItems active={selectedItem === id} onClick={() => onSelected(id)}>
      <S.ItemRadioBox>
        <input
          type="radio"
          id={String(id)}
          value={id}
          name="checkup"
          checked={selectedItem === id}
        />
        <label htmlFor="id" />
      </S.ItemRadioBox>

      <S.ItemContent>
        <S.ItemBox>
          <S.ItemTit>키</S.ItemTit>
          {checkup.height}cm
        </S.ItemBox>
        <S.ItemBox>
          <S.ItemTit>몸무게</S.ItemTit>
          {checkup.weight}kg
        </S.ItemBox>
        <S.ItemBox>
          <S.ItemTit>허리둘레</S.ItemTit>
          {checkup.WAIST} inch
        </S.ItemBox>
        <S.ItemBox>
          <S.ItemTit>당화혈색소</S.ItemTit>
          {checkup.HbA1c}%
        </S.ItemBox>
      </S.ItemContent>
      <S.ItemDateBox>{pointDate}</S.ItemDateBox>
    </S.HistoryItems>
  );
};
