import React from "react";
import * as S from "./hemoTemplate.styles";

interface HemoTemplateProps {
  children: React.ReactElement;
}

export const HemoTemplate = ({ children }: HemoTemplateProps) => {
  return <S.HemoTemplate>{children}</S.HemoTemplate>;
};
