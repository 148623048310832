import { useState } from "react";
import { Button } from "../../atoms/Button";
import { HistoryItems } from "../../atoms/HistoryItems";
import { HistoryListBox } from "../../molecules/HistoryListBox";
import { editHealthProps } from "../../../pages/Home/HemoInfo";

interface HistoryListProps {
  healthHistory: editHealthProps[];
  onDeleteHistory: () => void;
  handleSelected: (id: number) => void;
  selectedItem: number;
}
export const HistoryList = ({
  healthHistory,
  onDeleteHistory,
  handleSelected,
  selectedItem,
}: HistoryListProps) => {
  return (
    <HistoryListBox
      title={
        <>
          건강정보 히스토리
          {/* 삭제버튼 */}
          <Button
            type="button"
            color="white"
            size="xsm"
            label="선택삭제"
            onClick={onDeleteHistory}
          />
        </>
      }
    >
      {healthHistory?.map((data: any, idx: number) => {
        return (
          <HistoryItems
            id={data.id}
            onSelected={handleSelected}
            selectedItem={selectedItem}
            checkup={data ?? []}
            key={idx}
          />
        );
      })}
    </HistoryListBox>
  );
};
