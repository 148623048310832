import styled, { css } from "styled-components";
import { InputTypeBox } from "../Input/input.styles";

interface StringProps {
  kor?: boolean;
  rows?: number;
}

export const InfoItems = styled.div<StringProps>`
  display: -webkit-box;
  display: flex;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.divideBorder};
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 12px;

  &:nth-of-type(1),
  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-top: 0;
    border-top: none;
  }

  ${(props) =>
    props.rows &&
    css`
      grid-column: 1 / ${props.rows + 1};
    `}

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const InfoItemTit = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.unitTxt};
`;

export const InfoItemContent = styled.div<StringProps>`
  display: -webkit-box;
  display: flex;
  color: ${(props) => props.theme.colors.darkTxt};
  font-size: 2rem;
  font-weight: 500;
  font-family: "Roboto";
  -webkit-box-align: end;
  align-items: flex-end;
  gap: 3px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  ${(props) =>
    props.kor &&
    css`
      padding-bottom: 2px;
      font-size: 1.8rem;
      font-family: Noto Sans KR !important;
    `}

  ${InputTypeBox} {
    width: calc(100% - 5px);

    input {
      height: 34px !important;
    }
  }
`;

export const InfoItemUnit = styled.span`
  padding-bottom: 2px;
  color: ${(props) => props.theme.colors.darkTxt};
  font-size: 1.4rem;
  font-weight: 400;
`;
