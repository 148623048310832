import * as S from "./loadingSection.styles";

export const LoadingSection = () => {
  return (
    <S.LoadingSection>
      <S.LoadingBox>
        <S.LoadingAnimation>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </S.LoadingAnimation>
        <S.LoadingTxt>데이터를 불러오는 중입니다...</S.LoadingTxt>
      </S.LoadingBox>
    </S.LoadingSection>
  );
};
