import styled from "styled-components";

export const HemoTemplate = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  padding: 16px;
  background: ${(props) => props.theme.colors.mainBg};
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 20px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
