import styled, { css } from "styled-components";
import { InputProps } from ".";

interface InputLabelProps {
  itemLength?: number;
}

export const InputBox = styled.div<InputProps>`
  display: -webkit-box;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.labelTxt};
  font-size: 1.4rem;
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 5px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      ${InputTypeBox} {
        background: ${(props) => props.theme.colors.inputBg} !important;
      }
    `}

  .err-message {
    color: ${(props) => props.theme.colors.dottedTxt};
  }
`;

export const InputLabelBox = styled.div`
  display: -webkit-box;
  display: flex;
  align-items: center;

  .necessary {
    margin-left: 2px;
    color: ${(props) => props.theme.colors.dottedTxt};
    font-weight: 600;
  }
`;

export const InputTypeBox = styled.div`
  display: -webkit-box;
  display: flex;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};

  // 텍스트, 비밀번호
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="password"] {
    width: 100%;
    height: 42px;
    padding: 0 10px;
    color: ${(props) => props.theme.colors.black};
    font-size: 1.6rem;
    border: none;
    background: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.inputBorder};
      font-weight: 300;
    }
  }

  // 라디오 버튼
  input[type="radio"] {
    display: none;
  }

  // 콤보박스
  select {
    width: 100%;
    height: 42px;
    padding: 0 10px;
    color: ${(props) => props.theme.colors.black};
    border: none;
    background: none;
  }
`;

//라디오 라벨 스타일(버튼타입) 설정
export const InputRadioGroup = styled.div<InputLabelProps>`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => "repeat(" + props.itemLength + ", 1fr)"};
  gap: 1px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 1px;
    }
  }

  ${InputTypeBox} {
    padding: 0;
    border: none;

    // 레이블 버튼
    label {
      display: -webkit-box;
      display: flex;
      width: 100%;
      height: 48px;
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.labelTxt};
      background: ${(props) => props.theme.colors.whiteActive};
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    &:first-of-type {
      label {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-of-type {
      label {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // 레이블 버튼 선택 활성화
  input[type="radio"]:checked + label {
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    background: ${(props) => props.theme.colors.primary};
    box-shadow: ${(props) => props.theme.shadow.primary};
  }
`;

//체크박스 라벨 스타일(버튼타입) 설정
export const InputCheckGroup = styled.div<InputLabelProps>`
  display: -webkit-box;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.whiteActive};
  border-radius: 5px;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 10px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  ${InputTypeBox} {
    display: -webkit-box;
    display: flex;
    height: 48px;
    border: none;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;

    //IOS, safari 14 이하 버전 gap 적용
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      & > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    //체크박스
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }

    // 레이블 버튼
    label {
      display: -webkit-box;
      display: flex;
      color: ${(props) => props.theme.colors.black};
      font-size: 1.6rem;
    }
  }
`;

export const InputDateBox = styled.div`
  display: -webkit-box;
  display: flex;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};

  input {
    width: 100%;
    height: 42px;
    padding: 0 10px;
    color: ${(props) => props.theme.colors.black};
    font-size: 1.6rem;
    border: none;
    background: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.inputBorder};
      font-weight: 300;
    }
  }
`;
