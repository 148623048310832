import styled, { keyframes } from "styled-components";

const ringRotate = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(720deg);
  }
`;

const ringStroke = keyframes`
from,
  to {
    stroke-dashoffset: 452;
    transform: rotate(-45deg);
  }
  50% {
    stroke-dashoffset: 169.5;
    transform: rotate(-180deg);
  }
`;

export const LoginLoading = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100vw;
  height: 100vh;
  color: ${(props) => props.theme.colors.labelTxt};
  font-size: 1.6rem;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  flex-direction: column;
`;

export const LoadingCircleBox = styled.div`
  margin-bottom: 20px;

  .pl {
    display: block;
    width: 80px;
    height: 80px;
  }
  .pl__ring-rotate,
  .pl__ring-stroke {
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .pl__ring-rotate,
  .pl__ring-stroke {
    transform-origin: 80px 80px;
  }
  .pl__ring-rotate {
    animation-name: ${ringRotate};
  }
  .pl__ring-stroke {
    animation-name: ${ringStroke};
    transform: rotate(-45deg);
  }
`;
