import { InputHTMLAttributes } from "react";
import * as S from "./input.styles";
import { ErrorOption, UseFormRegisterReturn } from "react-hook-form";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  items?: any;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  children?: React.ReactElement;
  register?: UseFormRegisterReturn;
  errors?: any;
  step?: string;
  necessary?: boolean;
  onChange?: any;
}

export const Input = ({ disabled, children }: InputProps) => {
  return <S.InputBox disabled={disabled}>{children}</S.InputBox>;
};
