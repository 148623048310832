import { Button } from "../../atoms/Button";
import * as S from "./modal.styles";
import { ReactComponent as IconX } from "../../../styles/assets/svg/icon-x-symbol.svg";
import { useEffect } from "react";
interface ModalProps {
  title?: string;
  size?: string;
  padding?: number;
  label?: string;
  onClose?: () => void;
  children?: React.ReactElement;
  onTopClose?: () => void;
}

export interface ModalStyleProps {
  size?: string;
  padding?: number;
}

export const Modal = ({
  title,
  size,
  padding,
  onClose,
  children,
  label = "닫기",
  onTopClose,
}: ModalProps) => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <S.ModalBG>
      <S.ModalBox size={size} padding={padding}>
        <>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
            }}
          >
            <S.ModalHeader>{title}</S.ModalHeader>
            {onTopClose && <IconX height={20} onClick={onTopClose} />}
          </div>

          <S.ModalBody>{children}</S.ModalBody>

          {onClose && (
            <S.ModalFooter>
              <Button
                label={label}
                size="lg"
                color="primary"
                onClick={onClose}
              />
            </S.ModalFooter>
          )}
        </>
      </S.ModalBox>
    </S.ModalBG>
  );
};
