import * as S from "./historyListBox.styles";

export const HistoryListBox = ({ title, children }: any) => {
  return (
    <S.HistoryListBox>
      {title && <S.HistoryTit>{title}</S.HistoryTit>}
      <S.HistoryContent>{children}</S.HistoryContent>
    </S.HistoryListBox>
  );
};
