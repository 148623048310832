import { Button, ButtonGroup } from "../../atoms/Button";
import { InfoItems } from "../../atoms/InfoItems";
import { InfoListBox } from "../../molecules/InfoListBox";
import { InputText } from "../../../components/atoms/Input/InputText";
import { ReactComponent as IconEdit } from "../../../styles/assets/svg/icon-edit.svg";
import { ReactComponent as IconSave } from "../../../styles/assets/svg/icon-save.svg";
import { editHealthProps, healthData } from "../../../pages/Home/HemoInfo";
import { InputDate } from "../../atoms/Input/InputDate";

interface InfoListProps {
  healthData?: healthData;
  onEditHandler?: any;
  edit?: boolean;
  editHealthInfo?: editHealthProps;
  setEditHealthInfo?: any;
  onSaveEdit: () => void;
}

export const InfoList = ({
  healthData,
  onEditHandler,
  onSaveEdit,
  edit,
  editHealthInfo,
  setEditHealthInfo,
}: InfoListProps) => {
  // 오늘 날짜 기준 표기
  const today = new Date();
  const date = today.setDate(today.getDate());
  const defaultDateValue = new Date(date).toISOString().split("T")[0];
  const measure_date = healthData?.measure_date?.split("T")[0];
  return (
    <InfoListBox
      grid={true}
      header={
        <>
          <div>현재 건강정보</div>

          {/* 수정버튼 */}
          {edit === false ? (
            <Button
              type="button"
              name="edit"
              edit={true}
              onClick={onEditHandler}
            >
              <IconEdit />
            </Button>
          ) : (
            ""
          )}
          {/* 저장버튼 */}
          {edit && (
            <ButtonGroup array="end" width="50">
              <>
                <Button
                  type="button"
                  width="54px"
                  height={30}
                  name="save"
                  edit={true}
                  label="취소"
                  onClick={onEditHandler}
                />
                <Button
                  type="button"
                  width="60px"
                  name="save"
                  edit={true}
                  label="저장"
                  onClick={onSaveEdit}
                >
                  <>
                    <IconSave />
                  </>
                </Button>
              </>
            </ButtonGroup>
          )}
        </>
      }
    >
      <InfoItems
        title="성별"
        value={healthData?.sex === "1" ? "남자" : "여자"}
        kor={true}
      />
      <InfoItems title="나이" value={healthData?.age} />
      {/* 현재 건강정보 보기 */}
      {edit === false ? (
        <>
          <InfoItems title="키" value={healthData?.height} unit="cm" />
          <InfoItems title="몸무게" value={healthData?.weight} unit="kg" />
          <InfoItems title="허리둘레" value={healthData?.WAIST} unit="inch" />
          <InfoItems title="당화혈색소" value={healthData?.HbA1c} unit="%" />
          <InfoItems title="측정일자" value={measure_date} rows={3} />
        </>
      ) : (
        ""
      )}

      {/* 건강정보 수정 시 인풋 표기 */}
      {edit && (
        <>
          <InfoItems
            title="키"
            input={
              <InputText
                type="number"
                placeholder="cm"
                value={editHealthInfo?.height}
                onChange={({ target }: any) =>
                  setEditHealthInfo({ ...editHealthInfo, height: target.value })
                }
              />
            }
          />
          <InfoItems
            title="몸무게"
            input={
              <InputText
                type="number"
                placeholder="kg"
                value={editHealthInfo?.weight}
                onChange={({ target }: any) =>
                  setEditHealthInfo({
                    ...editHealthInfo,
                    weight: target.value,
                  })
                }
              />
            }
          />
          <InfoItems
            title="허리둘레"
            input={
              <InputText
                type="number"
                placeholder="inch"
                value={editHealthInfo?.WAIST}
                onChange={({ target }: any) =>
                  setEditHealthInfo({
                    ...editHealthInfo,
                    WAIST: target.value,
                  })
                }
                step={"0.1"}
              />
            }
          />
          <InfoItems
            title="당화혈색소"
            input={
              <InputText
                type="number"
                placeholder="%"
                value={editHealthInfo?.HbA1c}
                onChange={({ target }: any) =>
                  setEditHealthInfo({
                    ...editHealthInfo,
                    HbA1c: target.value,
                  })
                }
                step={"0.1"}
              />
            }
          />
          <InfoItems
            rows={3}
            title="측정일자"
            input={
              <InputDate
                defaultValue={defaultDateValue}
                onChange={({ target }: any) =>
                  setEditHealthInfo({
                    ...editHealthInfo,
                    measure_date: target.value,
                  })
                }
              />
            }
          />
        </>
      )}
    </InfoListBox>
  );
};
